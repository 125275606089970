import { useMediaQuery } from "@mui/material";

import SimpleArticle from '../Components/articles/SimpleArticle';
import DropDownArticle from "../Components/articles/DropDownArticle";
import infographie from '../Assets/illustrations/infographie.svg';

import PrimaryButton from '../Components/buttons/PrimaryButton';
import Sticker from '../Components/cards/Sticker';

const Infos = () => {
  const matches = useMediaQuery('(min-width:1080px)');


  return (
    <section className='page infos' id='infos'>
      <h3>La médiation animale, dans le détail</h3>
      <div className="intro-container">

        {matches && <Sticker scale="small" />}
        <SimpleArticle />

        <img src={infographie} alt="infographie relation_triadique" />
      </div>
      <DropDownArticle layout="image-center" title="A qui s’adresse la médiation par l’animal ?" />
      <DropDownArticle layout="cards-grid" title="Qelques bienfaits" />
      <DropDownArticle layout="colums-curve" title="Les ateliers des Patounes" />
      {!matches && <Sticker scale="small" />}
      <div className="cta-contact">
        <h4>Vous êtes intéressé ?</h4>
        <div className="button-container">
          <PrimaryButton color="primary" link="contact" content="contact" />
        </div>
      </div>
    </section>
  )

}

export default Infos;