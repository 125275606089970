
import eddu from "../pictures/eddu.JPG";
import breizh from "../pictures/breizh.jpg";
import macchiato from "../pictures/macchiato.jpg";
import eddu_2 from "../pictures/eddu_profile.jpg";
import macchiato_2 from "../pictures/macchiato_profile.jpg";
import lapins from "../pictures/breizh&macchiato.jpg";

import skoll from "../pictures/sköll.jpg";
import eddu_virg from "../pictures/eddu_virginia.jpg";
import dian_virg from "../pictures/diane_virginia.jpg";
import idle_skoll from "../pictures/idle_skoll.jpg";
import diane from "../pictures/diane.jpg";

const home_text = [
    {
        id:0,
        type: "p",
        text:"La médiation par l'animal, également appelée zoothérapie, est une pratique faisant appel à des animaux pour aider les personnes à améliorer leur bien-être et leur qualité de vie."
    },
    {
        id:1,
        type: "p",
        text:"Infirmière depuis 2006, en EHPAD depuis 2018, je me suis formée à la médiation par l'animal. De là est née, en février 2023, l'association loi 1901 \"Des Patounes pour un Sourire\" . Son but : créer du lien par le biais de la médiation par l'animal."
    }
]
const content_list = [
    {
    id: 0,
    type: "p",
    text: "La médiation par l’animal est une méthode permettant de créer une relation bienfaisante entre les animaux et les humains, dans le respect des règles élémentaires de prudence et de sécurité."
}, 
{
    id: 1,
    type: "p",
    text: "Dans cette relation, interviennent : ",
},
{
    id: 2,
    type: "ul",
    li: [
        "Un·e intervenant·e en médiation par l’animal et  un·e référent·e interne à la structure où a lieu l’intervention,",
        "Les bénéficiaires ( seul ou en groupe de 3 ou 4 personnes),",
        "Un animal, sélectionné et éduqué pour intervenir en tant qu’animal médiateur."]
},
{
    id: 3,
    type: "em",
    text: "C’est ce que Véronique Servais appelle la relation triadique."
}

]


const images_list = [{
    id: 0,
    alternative: "chien",
    src: eddu
},
{
    id: 1,
    alternative: "lapin",
    src: breizh
},
{
    id: 2,
    alternative: "lapin",
    src: macchiato
}
]
const images_strips = {
    "one": [
        {
            id: 0,
            alternative: "chien",
            src: eddu
        },
        {
            id: 1,
            alternative: "lapin",
            src: diane
        },
        {
            id: 2,
            alternative: "lapin",
            src: macchiato
        }
    ],
    "two":[{
        id: 0,
        alternative: "lapins",
        src: lapins
    },
    {
        id: 1,
        alternative: "chien",
        src: eddu_virg
    },
    {
        id: 2,
        alternative: "lapin",
        src: diane
    }],
    "three":[{
        id: 0,
        alternative: "chien",
        src: eddu_2
    },
    {
        id: 1,
        alternative: "lapin",
        src: diane
    },
    {
        id: 2,
        alternative: "lapins",
        src: lapins
    }]
}

export {
    content_list, images_list, home_text, images_strips
}