import { Link } from "react-scroll";
import { PiDogBold, PiHouseLineBold, PiCubeBold, PiInfoBold, PiHeartBold, PiPhoneDisconnectBold } from "react-icons/pi";
import { useState } from 'react';

const MenuIcons = (props) => {
    const [currentLocation, setCurrentLocation] = useState("home");

    const handleSetActive = (to) => {

        setCurrentLocation(to.toString())
    }


    return (
        <>
            <ul className="icon-menu" id={props.id}>
                <li className={currentLocation === "home" ? "active" : "inactive"}>
                    <Link
                
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        onSetActive={handleSetActive} >
                        <PiHouseLineBold className="icon"/>
                    </Link>
                </li>
                <li className={currentLocation === "activities-gallery" ? "active" : "inactive"}>
                    <Link
                        to="activities-gallery"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        onSetActive={handleSetActive}>
                        <PiCubeBold />
                    </Link>
                </li>
                <li className={currentLocation === "infos" ? "active" : "inactive"}>
                    <Link
                        to="infos"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        onSetActive={handleSetActive}>
                        <PiInfoBold />
                    </Link>
                </li>
                <li className={currentLocation === "animals-gallery" ? "active" : "inactive"}>
                    <Link
                        to="animals-gallery"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        onSetActive={handleSetActive}>
                        <PiDogBold />
                    </Link>
                </li>
                <li className={currentLocation === "actions" ? "active" : "inactive"}>
                    <Link
                        to="actions"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        onSetActive={handleSetActive}>
                        <PiHeartBold />
                    </Link>
                </li>
                <li className={currentLocation === "contact" ? "active" : "inactive"}>
                    <Link
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        onSetActive={handleSetActive}>
                        <PiPhoneDisconnectBold />
                    </Link>
                </li>
                <li className="tab-line"></li>
            </ul>
            
        </>)
}


export default MenuIcons;