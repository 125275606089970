import Logo from "../layouts/Header/Logo";
import { Link } from "react-scroll";
import { useLocation } from 'react-router-dom';


const Footer = () => {
  const location = useLocation();
  return (
    <footer>
      <section className="top">
        <div className="brand">
          <div className="brand-name">
            <Logo color="creme" />
            <p>Médiation par l'animal</p>
          </div>
          <ul className="contact-infos">
            <li><a href="mailto:despatounespourunsourire@gmail.com">despatounespourunsourire@gmail.com</a></li>
            <li>2 rue des Vergnes, 85600 Montaigu-Vendée</li>
            <li>06 75 43 27 16</li>
          </ul>
        </div>

        {
          location.pathname === '/' &&
          <div className="site-map">
            <h4>Plan du site</h4>
            <ul>
              <li><Link to="home" spy={true} smooth={true}>accueil</Link></li>
              <li><Link to="activities-gallery" spy={true} smooth={true}>activités</Link></li>
              <li><Link to="infos" spy={true} smooth={true}>infos</Link></li>
              <li><Link to="animals-gallery" spy={true} smooth={true}>nos animaux</Link></li>
              <li><Link to="contact" spy={true} smooth={true}>contact</Link></li>
              <li><a href="https://www.helloasso.com/associations/des-patounes-pour-un-sourire/adhesions/cotisation-annuelle" rel="noreferrer" target="_blank">nous rejoindre</a></li>
            </ul>
          </div>
        }
      </section>
      <section className="bottom">
        <ul>
          <li>Des patounes pour un sourire</li>
          <li>SIRET : 92321360800016</li>
          <li>Formée par l'institut <a href="http://www.agatea.org/" rel="noreferrer" target="_blank">AGATEA</a></li>
        </ul>
        {
          location.pathname === "/" &&
          <ul>
            <li><a href="/mentions-legales" rel="noreferrer" target="_blank">mentions légales</a></li>
          </ul>
        }
        {
          location.pathname == '/mentions-legales' &&
          <ul id="menu_list">
            <li className={location.pathname === "/" ? "active" : "inactive"}><a href="/">retour au site</a></li>
          </ul>
        }

        <p>site réalisé par <a href="https://github.com/blahkbyrd" rel="noreferrer" target="_blank">Blahkbyrd</a></p>
        <Link className="link-to-top" to="home_target" spy={true} smooth={true}>Haut de page</Link>
      </section>
    </footer>
  )
}
export default Footer;