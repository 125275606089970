import { useEffect, useState } from "react";

export const checkMediaQuery = (widthToTest, discriminate = "min") => {

    const width = window.matchMedia(`(${discriminate}-width: ${widthToTest}px)`)
    if(width.matches) return true;
    return false;
}

export const useMediaQuery = (widthToTest, discriminate) =>{
    const [res, setRes] = useState(false);
    useEffect(()=>{
        setRes(checkMediaQuery(widthToTest, discriminate))
    }, [widthToTest, discriminate]);
    return res;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    useMediaQuery
}

