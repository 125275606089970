import PrimaryButton from "../Components/buttons/PrimaryButton";
import TextCard from "../Components/cards/TextCard";
import PicturesStrip from "../Components/cards/PicturesStrip";
import { home_text } from "../Assets/data/home_data";

const Home = () => {
  const title_1 = "Qu'est ce que la médiation par l'animal ?";
  const title_2 = "L'asso des patounes en quelques mots";
  const content_1 = home_text[0].text
  const content_2 = home_text[1].text

  return (
    <section className='page home'>
      <aside >
        <div className="strips-container">
          <PicturesStrip pictures_order="one" />
          <PicturesStrip pictures_order="two" />
          <PicturesStrip pictures_order="three" />
        </div>
      </aside>
      <article>
        <TextCard title={title_2} content={content_2} button={false}/>
        <TextCard title={title_1} content={content_1} button={true}/>
      </article>
      <div className="button-container">
        <PrimaryButton link="contact" content="contact" color="primary" />
      </div>

    </section>
  )
}

export default Home;