import { useEffect, useState } from "react";
import logo from "../../Assets/logos/logo-bleu.png"
import illus from "../../Assets/illustrations/illus_bienfaits.png";


const FlippingCards = (props) => {
  const [flip, setFlip] = useState(props.State);

  const handleClick = () => {
    setFlip(!flip);
  }

  useEffect(()=>{
    setFlip(false);
  }, [props.state])


  return (
    <div className={`${props.state ? "rotate-cards" : ""} cards ${flip ? "active" : "inactive"}`}>
      <div className="inner-card" onClick={handleClick}>
        <div className="front">
          <img src={flip ? props.image : logo} alt="logo" />
        </div>
        <div className="back" >
          <p>{props.content}</p>
        </div>
      </div>
    </div>
  )
}

export default FlippingCards;