import eddu_seance from "../pictures/promenade.png"
import handicap from "../pictures/handicap.jpg"
import zootherapie from "../pictures/zootherapie.jpg"
import ehpad from "../pictures/ehpad.jpeg"
import fauteuil from "../pictures/fauteuil_roulant.jpeg"
import eddu from "../pictures/eddu_profile.jpg";
import breizh from "../pictures/breizh_profile.jpg";
import macchiato from "../pictures/macchiato_profile.jpg";
import skoll from "../pictures/sköll.jpg";
import eddu_virg from "../pictures/eddu_virginia.jpg";
import dian_virg from "../pictures/diane_virginia.jpg";
import diane from "../pictures/diane.jpg";
import actions from "../pictures/groupe_promenade.jpg";
import drop from "../icons/drop.png";
import leaf from "../icons/leaf.png";
import bone from "../icons/bone.png";
import carot from "../icons/carrot.png";
import eddu_work from "../pictures/Eddu table.png";
import group from "../pictures/groupe.png";
import rabbits from "../pictures/pinpin.png";
import dog from "../pictures/dudu.png";
import old_lady from "../pictures/dame.png";



const images_list = [{
    id: 0,
    name: "eddu_seance",
    legend: "stimuler la motricité",
    alternative: "mediation activité",
    src: eddu_seance
},
{
    id: 1,
    name: "handicap",
    legend: "lutter contre l'exclusion",
    alternative: "mediation activité",
    src: handicap
},

{
    id: 2,
    name: "diapo_eddu",
    legend: "Motricité avec un chien",
    alternative: "séance de zoothérapie",
    src: dog
},

{
    id: 3,
    name: "fauteuil_roulant",
    legend: "apporter du bien-être",
    alternative: "mediation activité",
    src: fauteuil
},
{
    id: 4,
    name: "eddu en séance",
    legend: "Eddu en séance",
    alternative: "séance de zoothérapie",
    src: eddu_work
},

{
    id: 5,
    name: "diapo_lapins",
    legend: "Travail sur les acquis cognitifs",
    alternative: "séance de zoothérapie",
    src: rabbits
},

]

const volunteer_list =
    [{
        id: 0,
        name: "La_Josephine",
        legend: "Participation à la course 'La Jospéhine', pour la lutte contre le cancer du sein",
        alternative: "octobre rose - La Josephine",
        src: actions
    },
    {
        id: 1,
        name: "La_Josephine",
        legend: "Octobre Rose avec Eddu",
        alternative: "octobre rose - La Josephine",
        src: eddu_virg
    }
]



// video => entretien et développement de l'autonomie

const animals_list = [
    {
        id: 0,
        name: "Eddu",
        icon: drop,
        type: "chien",
        features: ["Labrador chocolat", "poids: 30kg"],
        content: [
            { title: "Sociable", content: " Joueur et câlin. Il adore l'eau" }
        ],
        abstract: "Apaise les tensions",
        weakness: "Gourmand",
        weakness_ico: bone,
        alternative: "chien",
        src: eddu
    },
    {
        id: 3,
        name: "Diane",
        icon: leaf,
        type: "lapin",
        features: ["lapin nain", "poids: 2.4k"],
        content: [
            { title: "Intelligente", content: "Malgré son petit caractère, Diane est très caline." }
        ],
        abstract: "Vive et intelligente",
        alternative: "lapins",
        weakness: "Ronchon",
        weakness_ico: carot,
        weakness_alt: "ico",
        src: diane
    },
]

// const video_list = [
//     {
//         id: 0,
//         name: "seance_ehpad",
//         legend: "entretien et développement de l'autonomie",
//         src: seance_ehpad_macchiato
//     }
// ]

export {
    images_list,
    animals_list,
    volunteer_list
}

