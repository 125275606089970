import Imagecard from "../cards/ImageCards";
import { images_strips } from "../../Assets/data/home_data";

const PicturesStrip = (props) => {
  const picture_order = props.pictures_order
  return (
    <div className={`strip-container ${picture_order}`}>
      <ul>{
        images_strips[picture_order].map(element => {
          return (<li className="strip-image" key={element.id}><Imagecard source={element.src} alternative={element.alternative} /></li>)
        })
      }
      </ul>
    </div>
  )
}
export default PicturesStrip;