import { Link } from "react-scroll"


const SecondaryButton = () => {
  return (

    <Link to="infos" spy={true} smooth={true}>
      <div className="button-plus">
        <div className="line-plus"></div>
        <div className="line-plus"></div>
      </div>
    </Link>

  )
}
export default SecondaryButton;