import { useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";


export const ContactForm = () => {

  const { register, formState: { isValid }, reset } = useForm();

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE;
  const pubK = process.env.REACT_APP_EMAILJS_PUBK;

  // SEND EMAIL
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();
    if (isValid) {
      emailjs.sendForm(serviceId, 'template_l02zcve', form.current, pubK)
        .then(
          (result) => { showConfirmation("Message envoyé") },
          (error) => {
            showConfirmation("Un erreur s'est produite, veuillez réessayez plus tard")
          },
        )
    }
    reset()


  }

  const handleClick = (e) => {
    e.preventDefault();
    reset();
  }

  const showConfirmation = (message) => {
    alert(message);
  }


  return (
    <div className='form-container'>
      <form ref={form} onSubmit={sendEmail} method="post">
        <label htmlFor="email">email :</label>
        <input type="email" required={true} name="email" id="email" defaultValue="john-do@mail.com" {...register("from_name")} />
        <label htmlFor="name">nom</label>
        <input required={true} type="text" name="name" id="name" defaultValue="John Doe" {...register("name")} />
        <label htmlFor="message" >message</label>
        <textarea required={true} name="message" id="message" cols="60" rows="10" placeholder="Votre message ..." {...register("message")}></textarea>
        <div className="button-container">
          <button className="btn primary-btn" type="submit">envoyer</button>
          <button onClick={e => handleClick(e)} className="btn cancel-btn" type="button">annuler</button>
        </div>
      </form>
    </div>
  )
}


