import { ContactForm } from "../Components/others/ContactForm";
import Sticker from "../Components/cards/Sticker";
import { log } from "xstate/lib/actions";

const Contact = () => {

  
  return (
    <section className='page contact' id="contact">
      <h3>Contact</h3>
      <div className="contact-container">
        <ContactForm />
        <Sticker scale="big" />
      </div>
    </section>
  )
}

export default Contact;