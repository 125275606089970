import { volunteer_list } from "../Assets/data/gallery_data";
import { useEffect, useRef } from "react";
import Imagecard from "../Components/cards/ImageCards";
import { changeClassOfNodeElement } from "../Utils/utils"

const VolunteerAction = () => {
    const tabs_legend = useRef(null);
    const tabs_image = useRef(null);
    const tab_id = useRef(null);
    const tab_line = useRef(null);

    const number_of_images = volunteer_list.length;
   
    const activePicture = (id) => {
        let current_legend = tabs_legend.current.childNodes;
        let current_image = tabs_image.current.childNodes;
        let tab = tab_id.current.childNodes;

        changeClassOfNodeElement(current_legend, current_legend[id], "active");
        changeClassOfNodeElement(current_image, current_image[id], "active");
        changeClassOfNodeElement(tab, tab[id], "active");
    }

    useEffect(() => {
        activePicture(0);
        const r = document.querySelector(':root');
        r.style.setProperty('--number_images_diapo_2', `${number_of_images}`);
    }, [])

    const handleClick = (id, e) => {
        activePicture(id)
    }
    return <section className="page actions" id="volunteer-actions">
        <h3>Nos Actions</h3>
        <div className="gallery-tab-container">
                <div ref={tabs_image} className="image-grid horizontal-scroll">
                    {
                        volunteer_list.map(elem => {
                            return (
                                <div key={elem.id} onClick={(e) => handleClick(elem.id, e)} className="image-container">
                                    <Imagecard id={`${elem.name}-${elem.id}`} name={elem.name} source={elem.src} alternative={elem.alternative} />
                                    <p className="legend">{elem.legend}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div ref={tabs_legend} className="picture-legend">
                    {
                        volunteer_list.map(elem => {
                            return (
                                <p key={elem.id} className="legend">{elem.legend}</p>
                            )
                        })
                    }
                </div>
                <div className="tab" ref={tab_id}>
                    {
                        volunteer_list.map(elem => {
                            return (
                                <button className="number_button" key={elem.id} onClick={(e) => handleClick(elem.id, e)}>{elem.id + 1}</button>
                            )
                        })
                    }
                    <div ref={tab_line} className="tab-line"></div>
                </div>
            </div>

        </section>
    
}



export default VolunteerAction;