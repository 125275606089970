import benefits_illus from "../../Assets/illustrations/illus_bienfaits.png";

const Circle = (props) => {

    let benefits = props.list
    return (
        <div className="circle-container">
             <div className="center-of-circle">
                    <img className="benefits-illustration" src={benefits_illus} alt="bienfaits illustration" />
                </div>
            <div className="circle-elements ">
               
                {
                    benefits.map(elem => (
                        <div key={elem.id} className="benefits-element-container">
                            <p className="benefits-element">{elem.content} </p>
                        </div>

                    ))
                }
            </div>

        </div>
    )
}

export default Circle;