import React from 'react'

const ImageCards = (props) => {

  const source = props.source;
  const alternative = props.alternative;
  return (
      <div className="image-card"  >
        <img src={source} alt={alternative} id={props.id} />
      </div>
    
  )
}

export default ImageCards;