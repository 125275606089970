import { ThemeContext } from '../../App';
import  {useState, useContext} from 'react';
import { PiSunBold, PiMoonFill } from "react-icons/pi";



const ToggleThemeButton = ({children}) => {
    const theme= useContext(ThemeContext)
    

    const [checked, setChecked] = useState(false);
    const handleChange = () =>{
        
        setChecked(!checked);
        theme.toggleTheme()
    }
    
    
    return <div className="toggle-btn">
        <label className='toggle-label'>
        <input type='checked' checked={checked}  id={checked ? "check" : "uncheck"} />
        <span className='toggle-slider' onClick={handleChange} ><PiSunBold className='dark-mode-icon sun'/> <PiMoonFill className='dark-mode-icon moon'/></span>
        </label>
    </div>;
}



export default ToggleThemeButton;