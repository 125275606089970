import PrimaryButton from "../buttons/PrimaryButton";
import icon from "../../Assets/logos/logo-creme.png";

const Sticker = (props) => {
  const link = "https://www.helloasso.com/associations/des-patounes-pour-un-sourire/adhesions/cotisation-annuelle";

  return (
    <div className={`sticker-container ${props.scale}`}>
      <h4>Rejoindre la meute !</h4>
      {
        props.scale === "big" && <span>sur</span>
      }
      <div className='button-container'>
        <PrimaryButton link={["ext", link]} color="secondary" content="hello asso" />
        <img className="icon" src={icon} alt="icon emprunte d'animal" />
        <img className="icon" src={icon} alt="icon emprunte d'animal" />
      </div>
      {
      props.scale === "big" && 
      <p>Merci pour votre soutien !</p>
      }
    </div>
  )
}

export default Sticker;