import { useState, useContext } from "react";
import Navbar from "../layouts/Header/Navbar";

import MenuIcons from "../layouts/Header/MenuIcons";
import { SideBarContext } from "../../App";


const Burger = ({children}) => {
  const sidebar = useContext(SideBarContext);

  const [isOpen, setIsOpen] = useState(false);

  const toggle_menu = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    sidebar.toggleSideBar();
  }

  return (

    <div className="mobile-menu" id={`menu-${sidebar.sidebar}`}>

      <div className='burger-container' id={sidebar.sidebar} onClick={toggle_menu}>
        <div className="lines">
          <div id="line-1"></div>
          <div id="line-2"></div>
          <div id="line-3"></div>
        </div>
      </div>

      <Navbar id={sidebar.sidebar} type="mobile" />

      <MenuIcons id={`icon-menu-${sidebar.sidebar}`}/>

    </div>
  )
}

export default Burger;