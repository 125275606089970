import isolated from "../illustrations/isolement.jpg";
import motricity from "../illustrations/motricite.jpg";
import cognitivity from "../illustrations/dev_coginitif.jpg";
import good_being from "../illustrations/apport_bien-etre.png";
import feelings from "../illustrations/cat_dog.jpg";
import exclusion from "../illustrations/lutte_exclusion.png";
import sensibilisation from '../illustrations/pedagogie.png';
import relief from '../illustrations/soulagement_souffrance.png';

const info_data =  {
    intro: "Toute personne, quelque soit son âge, sa situation, son état de santé, peut bénéficier de séance de médiation par l’animal, à son domicile ou en structure.",
    sections: [
        {
            id: 0,
            title: "enfants",
            content: [
                "à la crèche, à l’école, à l’hôpital ... ",
                "enfants présentant des handicaps"
            ]
        },
        {
            id: 1,
            title: "personnes âgées",
            content: [
                "autonomes ou dépendantes",
                "personnes isolées",
                "personnes souffrant de démence"
            ]
        },
        {
            id: 2,
            title: "adultes",
            content: ["adultes en situation de handicap" , "personnes socialement isolées"]
        }
    ],
    illustration:{
        src:"",
        alt:"people"
    }
}
const benefits_data = [
    {id:0,content:"Soutien aux personnes isolées", image:isolated},
    {id:1,content:"Aide à l’expression des émotions", image:feelings},
    {id:2,content:"Lutte contre l’exclusion", image:exclusion},
    {id:3,content:"Aide à la communication", image:feelings},
    {id:4,content:"Soulagement des souffrances", image:relief},
    {id:5,content:"Sensibilisation et pédagogie", image:sensibilisation},
    {id:6,content:"Stimulation motrice et cognitive", image:motricity},
    {id:7,content:"Entretien et développement de l’autonomie", image:cognitivity},
    {id:8,content:"Apport de bien-être", image:good_being},
]

const workshop_data = [
    {id:0,title:"animations", subtitle:"ponctuelles", content:"Les animations sont des séances ponctuelles. L’objectif est de passer un moment agréable tout en découvrant la médiation par l’animal."},
    {id:1,title:"programmes", subtitle:"hebdomadaire ou bi-mensuelle", content:"La mise en place d’un programme permet d’élaborer une stratégie, à moyen ou long terme, afin d’atteindre des objectifs fixés par le référant de la structure et l’intervenant en médiation par l’animal."}
]
export {info_data, benefits_data, workshop_data};