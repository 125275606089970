// import SimpleArticle from "../articles/SimpleArticle"
import SecondaryButton from "../buttons/SecondaryButton";

const TextCard = (props) => {
  const title = props.title;
  const content = props.content;
  return (
    <div className="card text-card">
      <h4>{title}</h4>
      <div className="text-container">
        <p className="text">
         {content}
        </p>
      </div>
      {
        props.button && <SecondaryButton />
      }
      
    </div>
  )
}
export default TextCard