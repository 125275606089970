import PokemonStyleCards from "../Components/cards/PokemonStyleCards";
import {animals_list} from "../Assets/data/gallery_data";
import { log } from "xstate/lib/actions";

const AnimalsGallery = (props) => {

  return (
    <section className='page animals-gallery' id='animals-gallery'>
      <h3>Nos animaux</h3>
      <div className="animals-gallery-container">
        {
          animals_list.map(el=>{
            return (
              
              <PokemonStyleCards
                id={el.id}
                features={el.features}
                content={el.content}
                type={el.type}
                name={el.name}
                icon={el.icon}
                img_src={el.src}
                img_alt={el.alternative}
                weakness={el.weakness}
                weakness_alt={el.weakness_ico}
                weakness_icon={el.weakness_ico}
                abstract={el.abstract}
              />
            )
          })
        }
      </div>
    </section>
  )
}


export default AnimalsGallery;