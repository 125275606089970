import Home from "../../Pages/Home";
import Infos from "../../Pages/Infos";
import AnimalsGallery from "../../Pages/AnimalsGallery";
import Contact from "../../Pages/Contact";
import GalleryActivity from "../../Pages/GalleryActivity";
import VolunteerAction from "../../Pages/VolunteerAction";


const MainLayout = () => {
  return (
    <div>
      <Home/>
      <GalleryActivity/>
      <Infos/>
      <AnimalsGallery/>
      <VolunteerAction/>
      <Contact/>
    </div>

  )
}

export default MainLayout;