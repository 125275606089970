import { useEffect, useRef } from "react";
import { animate } from "framer-motion";
import { useMediaQuery } from "../../Utils/mediaQuery";

import balade from "../../Assets/pictures/en_balade.jpg";
import { info_data } from "../../Assets/data/info_data";
import { selectChildNodeElement } from "../../Utils/utils";



const ImageAtCenter = (props) => {
  const anim = props.state;
  const content = info_data;
  const drop = useRef(null);
  // const container = useAnimate()
  // const intro = useAnimate()
  // const image = useAnimate()

  const container = useRef();
  const intro = useRef();
  const image = useRef();

  const match = useMediaQuery(1300, "min");
  const image_src = balade
  useEffect(() => {

    const children = selectChildNodeElement(container);

    if (match && anim) {
      animate(".intro", {y:0, opacity:1}, {duration:0.5})
      animate(children[0], {x:50, opacity:1}, {delay:0.5, duration:2})
      animate(children[1], {x:100, opacity:1}, {delay:1, duration:2})
      animate(children[2], {x:150, opacity:1}, {delay:1.5, duration:2})
    }
    else if (!match && anim) {
      animate(".intro", {y:0, opacity:1}, {duration:0.5})
      animate(children[0], {x:0, opacity:1}, {delay:0.5, duration:1})
      animate(children[1], {x:50, opacity:1}, {delay:1, duration:1})
      animate(children[2], {x:100, opacity:1}, {delay:1.5, duration:1})
    }
    else {
      animate(".intro", {y:"-100%", opacity:1}, {duration:0.5})
      animate(children[0], {x:"300%", opacity:0}, {delay:1,duration:1})
      animate(children[1], {x:"300%", opacity:0}, {delay:1,duration:1})
      animate(children[2], {x:"300%", opacity:0}, {delay:1,duration:1})
    }

  })

  return (
    <div ref={drop} className="image-center-layout-container">
      <p ref={intro} className="intro">{content.intro}</p>
      {
        match &&  <img ref={image} src={image_src} alt={content.illustration.alt} />
      }
     
      <div ref={container} className="parent">
        {
          content.sections.map((elem) => {
            return (
              <div key={elem.id} className="children">
                <h5>{elem.title}</h5>
                {
                  elem.content.map((el, id) => {
                    return (
                      <div key={id}>{el}</div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default ImageAtCenter;