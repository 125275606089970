import React from 'react'

const Legacy = () => {
  return (
    <section className='page legacy'>
      <div className='intro'>
        <h1>Mentions légales</h1>
        <p className='date'>En vigueur au 05/09/2023</p>
        <article>
          <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004
            pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des
            utilisateurs et visiteurs, ci-après <strong>" l'utilisateur "</strong>, du site despatounespourunsourire.fr , ci-après le <strong>"Site"</strong>,
            les présentes mentions légales.</p>
          <p>La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</p>
          <p>Ces dernières sont accessibles sur le Site à la rubrique <strong>« Mentions légales »</strong>.</p>
        </article>
      </div>
      <div className=' article article_1'>
        <h2>Article 1 - l'éditeur</h2>
        <article>
          <p>L'édition du Site est assurée par <strong>Des Patounes Pour Un Sourire</strong> Association au capital de 0 euros,
            immatriculée au Registre du Commerce et des Sociétés de Montaigu-Vendée sous le numéro 92321360800016,
            dont le siège social est situé au 2 rue des Vergnes, 85600 Montaigu-Vendée.
          </p>
          <ul>
            <li>Numéro de téléphone : 06 75 43 27 16</li>
            <li>Adresse e-mail : despatounespourunsourire@gmail.com</li>
          </ul>
          <p>Le Directeur de la publication est Virginia Trinchero ci-après l'<strong>"Editeur"</strong>.</p>
        </article>
      </div>
      <div className='article artcile_2'>
        <h2>Article 2 - l'hébérgeur</h2>
        <p>L'hébergeur du Site est la société Gandi SAS, dont le siège social est situé au 63-65 boulevard Masséna Paris (75013) FRANCE ,
          avec le numéro de téléphone : 01 43 73 18 51
        </p>
      </div>
      <div className='article artcile_3'>
        <h2>Article 3 - l'accès au site</h2>
        <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure,
          interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
        </p>
      </div>
      <div className='article artcile_4'>
        <h2>Article 4 - collecte des données</h2>
        <p>Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL)
          dans la mesure où il ne collecte aucune donnée concernant les utilisateurs.Toute utilisation,
          reproduction, diffusion, commercialisation, modification de toute ou partie du Site﻿,
          sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites
          judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
        </p>
      </div>
      <p className='sig'>Rédigé sur <a href='https://legalplace.fr' target="_blank" >https://legalplace.fr</a></p>
    </section>
  )
}

export default Legacy;