import { content_list } from "../../Assets/data/home_data"
import print from "../../Assets/logos/logo-gris.png"

const SimpleArticle = () => {
  return (
    <article className="article-container">
      {
        content_list.map(elem => {
          switch (elem.type) {
            case "p":
              return (<p key={elem.id}>{elem.text}</p>)
            case "ul":
              return (<ul className="article-list" key={elem.id}>
                {
                  elem.li.map((el, id) => (
                    <li className="article-list-item" key={id}>
                      <img  className="bullet-list" src={print} alt="emprunte"/>
                      <p>{el}</p>
                    </li>
                  ))
                }
              </ul>)
            case "em":
              return (<p key={elem.id}><em>{elem.text}</em></p>)
            default:
              return (<p></p>)
          }
        })
      }
    </article>
  )
}


export default SimpleArticle

/*
     <p>{content_list[0].text}</p>
     <p>{content_list[1].text}</p>
*/