import React from 'react'

const Banner = () => {
  return (
    <div className='banner-container'>
      <h1 className="brand-name">Des Patounes pour un Sourire</h1>
      <h2 className="baseline">médiation par l'animal</h2>
    </div>
  )
}
export default Banner;