import { useMediaQuery } from "@mui/material";

import { benefits_data } from "../../Assets/data/info_data";
import FlippingCards from "../cards/FlippingCards";
import Circle from "./Circle";
import { useRef } from "react";

export const CardsGrid = (props) => {
  const matches = useMediaQuery("(min-width:1080px)");
  const cardContainer = useRef();
  const benefits = benefits_data;

  return (
    <>
      {
        !matches && 
        <div ref={cardContainer} className="card-grid-layout-container">
          {
            benefits.map((elem) => 
              (
                <FlippingCards key={elem.id} content={elem.content} state={props.state} image={elem.image} />
              )
            )
          }
        </div>
      }
      {
        matches && 
        <div className="circle-layout-container">
          <Circle list={benefits}/>
        </div>
      }
    </>

  )
}

export default CardsGrid;