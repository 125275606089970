import { workshop_data } from "../../Assets/data/info_data";

const ColumsAndCurve = (props) => {

  return (
    <div className="colum-curve-layout-container">
      {
        workshop_data.map((data,id) =>{
          return (
            <div key={id} className="col">
              <h5>{data.title}</h5>
              <h6>{data.subtitle}</h6>
              <p>{data.content}</p>
            </div>
          )
        })
      }

    </div>
  )
}

export default ColumsAndCurve;

