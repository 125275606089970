import { useState } from 'react'
import logo from "../../Assets/logos/logo-creme.png";
import { log } from 'xstate/lib/actions';



const PokemonStyleCards = (props) => {
  const isflip = props.id == 0; 
  const [flip, setFlip] = useState(isflip);

  const handleClick = () => {
    setFlip(!flip);
  }

  const features = props.features;
  const content = props.content;

  return (
    <div onClick={handleClick} className={`${flip ? "rotate-cards" : ""} cards`}>
      <div className="inner-card">
        <div className="front">
          <img src={logo} alt="logo" />
        </div>
        <div className="back">
          <div className="header-card">
            <p>{props.type}</p>
            <h5>{props.name}</h5>
            <img className="icon" src={props.icon} alt="icon" />
          </div>
          <div className="image-container">
            <img src={props.img_src} alt={props.img_alt} className="animal-img" />
            <ul className='features-list'>
              {
                features.map((el, id) => {
                  return (
                    <li key={id}>{el}</li>
                  )
                })
              }
            </ul>
          </div>
          <div className="content">
            {
              content.map((el, id) => {
                return (
                  <div key={id} >
                    <h6>{el.title}</h6>
                    <p>{el.content}</p>
                  </div>
                )
              })
            }
          </div>
          <div className="footer-card">
            <p className="weakness">faiblesse : <span>{props.weakness}</span></p>
            <img className='icon' src={props.weakness_icon} alt={props.weakness_alt} />
            <p className='absract'>{props.abstract}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PokemonStyleCards;