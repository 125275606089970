import { info_banner_data } from "../../Assets/data/info_banner_data";

const HorizontalScrollText = () => {
    const content = info_banner_data[0];

    return (
    <div className={content.is_online ? "scroller_horizontal_container" : "no-display"} >
        <div
            className="scroller_horizontal_text"
            data-text={content.content}>
            <span></span>
        </div>
    </div>
    )
}

export default HorizontalScrollText;