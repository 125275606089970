import { Link } from "react-scroll"

const PrimaryButton = (props) => {
  const content = props.content;
  const link = props.link;
  const color = props.color;

  return (
    <button className={`btn ${color}-btn`}>
      {
        typeof link !== "object" &&
        <Link to={link} spy={true} smooth={true}>{content}</Link>
      }
      {
        typeof link == "object" &&
        <a href={link[1]} target="_blank" rel="noreferrer"  >{content}</a>
      }
    </button>
  )
}


export default PrimaryButton;