import { useLocation } from 'react-router-dom';
import { Link } from "react-scroll";
import { useState, useContext } from "react";
import { SideBarContext } from '../../../App';

const Navbar = ( {children, type}) => {
  const sidebar = useContext(SideBarContext);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("home");
  const handleSetActive = (to) => {
    setCurrentLocation(to.toString());
  }
  const closeMenu = () => {
    if (type === "mobile" && sidebar.sidebar === "open") {
      sidebar.toggleSideBar();
   
    }
  }

  return (
    <nav className='nav-container' id={type==="mobile"? sidebar.sidebar : "menu_list"}>
      {
        location.pathname == '/' &&
        <ul id={sidebar.sidebar}>
          <li className={currentLocation === "home" ? "active" : "inactive"}>
            <Link
              onClick={closeMenu}
              to="home"
              spy={true}
              smooth={true}
              offset={-50}
              onSetActive={handleSetActive} >
              accueil
            </Link>
          </li>
          <li className={currentLocation === "activities-gallery" ? "active" : "inactive"}>
            <Link
              onClick={closeMenu}
              to="activities-gallery"
              spy={true}
              smooth={true}
              offset={-50}
              onSetActive={handleSetActive}>
              activités
            </Link>
          </li>
          <li className={currentLocation === "infos" ? "active" : "inactive"}>
            <Link
              onClick={closeMenu}
              to="infos"
              spy={true}
              smooth={true}
              offset={-50}
              onSetActive={handleSetActive}>
              infos
            </Link>
          </li>
          <li className={currentLocation === "animals-gallery" ? "active" : "inactive"}>
            <Link
              onClick={closeMenu}
              to="animals-gallery"
              spy={true}
              smooth={true}
              offset={-50}
              onSetActive={handleSetActive}>
              nos animaux
            </Link>
          </li>
          <li className={currentLocation === "actions" ? "active" : "inactive"}>
            <Link
              onClick={closeMenu}
              to="actions"
              spy={true}
              smooth={true}
              offset={-50}
              onSetActive={handleSetActive}>
              nos actions
            </Link>
          </li>
          <li className={currentLocation === "contact" ? "active" : "inactive"}>
            <Link
              onClick={closeMenu}
              to="contact"
              spy={true}
              smooth={true}
              offset={-50}
              onSetActive={handleSetActive}>
              contact
            </Link>
          </li>
          <li className="tab-line"></li>
        </ul>
      }
      {
        location.pathname == '/mentions-legales' &&
        <ul id="menu_list">
          <li className={location.pathname === "/" ? "active" : "inactive"}><a href="/">accueil</a></li>
        </ul>
      }
    </nav>
  )
}
export default Navbar;