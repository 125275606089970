import Logo from "./Logo";
import Banner from "./Banner";
import Navbar from "./Navbar";
import Burger from "../../buttons/Burger";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { shrinkHeader } from "../../../Utils/utils";
import HorizontalScrollText from "../../others/HorizontalScrollText";


const Header = () => {
  const matches = useMediaQuery('(min-width:1080px)');
  const [scrollValue, setScrollValue] = useState(0)
  useEffect(() => {
    window.onscroll = () => {
      if (matches && window.scrollY > scrollValue) {
        shrinkHeader(true);
        setScrollValue(window.scrollY);
      }
      if (matches && window.scrollY === 0) {
        shrinkHeader(false);
        setScrollValue(window.scrollY)
      }
    }
  }, [scrollValue, matches])



  if (matches) {
    return (
      <header id="header" className="regular">
        <HorizontalScrollText />
        <div className="brand-container">
          <Logo color="bleu" />
          <Banner />
        </div>
        <div className="line-gradient"></div>
        <Navbar />
      </header>
    )
  }
  return (
    <header id="header" className="regular">
      <HorizontalScrollText />
      <Logo color="clair" />
      <Banner />
      <Burger />
    </header>
  )
}


export default Header;