export const findId = (array, id) => {
    array.forEach(element => {
        if (element.id === id) {

            return 1
        }
    });
}

export const changeClassOfNodeElement = (nodeList, target, new_class) => {
    nodeList.forEach(child => {
        if (child === target) {
            child.classList.add(new_class)
        }
        else {
            child.classList.remove(new_class)
        }
    })
}

export const selectChildNodeElement = (parent) => {
    const element = parent.current.childNodes;
    const array = [];
    element.forEach(child => {
        array.push(child)
    })
    return array;
}

export const shrinkHeader = (isUp) => {

    const logo = document.getElementsByClassName("logo")[0]
    const banner = document.getElementsByClassName("baseline")[0];
    const nav = document.getElementById("menu_list")

    if(isUp){
        logo.style.height = "40px";
        banner.style.fontSize = "2.37em";
        banner.style.transform = "translateY(-50%)";
        nav.style.transform = "translateY(-20px)";
    }
    if(!isUp){
        logo.style.height = "100px";
        banner.style.fontSize = "3.12em";
        banner.style.transform = "translateY(0%)";
        nav.style.transform = "translateY(0%)";
    }
 
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    findId,
    changeClassOfNodeElement,
    selectChildNodeElement,
    shrinkHeader
}

